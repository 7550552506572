import { createSlice } from "@reduxjs/toolkit";

const initialState = { isOpen: false };

export const isDrawerOpen = createSlice({
  name: "isDrawerOpen",
  initialState,
  reducers: {
    handleToggleDrawer: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { handleToggleDrawer } = isDrawerOpen.actions;

export default isDrawerOpen.reducer;
