import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import { BsPlus, BsX } from "react-icons/bs";
import { AiOutlineMinus } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import FeaturesCards from "../components/landingpage/FeaturesCards";
import { HiOutlineMenu } from "react-icons/hi";
import { IoCaretForwardOutline } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleToggleDrawer } from "../features/isDrawerOpen";

const LandingPage = () => {
  return (
    <Stack bg="brand.primary" alignItems="center" spacing={0}>
      <Header />
      <Features />
      <HowItWorks />
      <FAQs />
      <RewardYourPassion />
      <Footer />
    </Stack>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isOpen } = useSelector((state: RootState) => state.isDrawerOpen);

  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [isOpen]);

  return (
    <Center w="100%" bgImage="/photoss.png" bgPos="center" bgSize="cover">
      <Stack
        w="100%"
        maxW="78rem"
        p={{ base: 5, md: "2rem 0" }}
        h={{ md: "100vh" }}
        color="white"
        spacing={5}
      >
        {/* Mobile drawer starts */}

        <Stack
          display={{ base: "flex", md: "none" }}
          zIndex={1001}
          p={5}
          pos="absolute"
          top={isOpen ? 5 : "-100vh"}
          right={5}
          left={5}
          m="auto"
          bg="white"
          borderRadius={10}
          alignItems="flex-start"
          spacing={5}
          transition="all 1s cubic-bezier(0.1, 0.6, 0.7, 1) 0s"
        >
          <HStack w="100%">
            <Image src="/kolet.svg" h="2rem" />
            <Spacer />
            <IconButton
              aria-label="Hamburger"
              variant="unstyled"
              color="black"
              display={{ base: "flex", md: "none" }}
            >
              <Icon
                onClick={() => dispatch(handleToggleDrawer())}
                as={BsX}
                color="black"
                boxSize={10}
              />
            </IconButton>
          </HStack>
          <Stack w="100%" alignItems="flex-start">
            <Button colorScheme="link" color="black" fontWeight={500}>
              Home
            </Button>
            <Button colorScheme="link" color="black" fontWeight={500}>
              Features
            </Button>
            <Button colorScheme="link" color="black" fontWeight={500}>
              FAQ
            </Button>
          </Stack>
          <Button
            colorScheme="none"
            bg="black"
            color="white"
            borderRadius={20}
            w="100%"
          >
            Download App
          </Button>
        </Stack>

        {/* Mobile drawer ends */}

        <HStack
          p=".5rem 1rem"
          bg="white"
          borderRadius={32}
          spacing={{ base: 2, md: 5 }}
          mb={{ base: 10, md: 20 }}
        >
          <Image
            draggable={false}
            src="/kolet.svg"
            h={{ base: "1.75rem", md: "2.5rem" }}
          />
          <Spacer />
          <HStack
            spacing={5}
            display={{ base: "none", md: "flex" }}
            style={{ fontWeight: "bold" }}
          >
            <Link color="black" href="/">
              Home
            </Link>
            <Link color="black" href="/">
              Features
            </Link>
            <Link color="black" href="/">
              FAQs
            </Link>
          </HStack>
          <Button
            onClick={() => navigate("/waiting-list")}
            colorScheme="blackAlpha"
            bg="black"
            fontWeight={700}
            borderRadius={32}
            _hover={{
              bg: "blackAlpha",
            }}
          >
            Download App
          </Button>
          <IconButton
            aria-label="Hamburger"
            variant="unstyled"
            color="black"
            display={{ base: "flex", md: "none" }}
            onClick={() => dispatch(handleToggleDrawer())}
          >
            <HiOutlineMenu size={20} />
          </IconButton>
        </HStack>
        <Heading
          w={{ base: "17rem", md: "4xl" }}
          fontSize={{ base: "2.5rem", md: "5rem" }}
          fontWeight={500}
        >
          Play unlimited games Earn with friends
        </Heading>
        <Text w={{ base: "xs", md: "md" }}>
          Discover more than just gaming on the Kolet app-immerse yourself in
          entertainment, build real connections, and empower your finaces, all
          in once place!
        </Text>
        <HStack spacing={5}>
          <Button
            onClick={() => navigate("/waiting-list")}
            variant="unstyled"
            w="8rem"
            h="3rem"
          >
            <Image draggable={false} src="/playstore.png" w="100%" h="100%" />
          </Button>
          <Button
            onClick={() => navigate("/waiting-list")}
            variant="unstyled"
            w="8rem"
            h="3rem"
          >
            <Image draggable={false} src="/appstore.png" w="100%" h="100%" />
          </Button>
        </HStack>
        <HStack
          bg="#0D355BB2"
          p={3}
          spacing={5}
          w="fit-content"
          alignSelf="flex-end"
          borderRadius={10}
          mt={{ base: 20, md: 0 }}
        >
          <IconButton boxSize={10} aria-label="" p={0} colorScheme="none">
            <Image
              draggable={false}
              src="/outline-game-01.png"
              w="100%"
              h="100%"
            />
          </IconButton>
          <HStack
            color="white"
            bg="brand.primary"
            p="0.35rem 0.5rem"
            borderRadius={10}
          >
            <IconButton boxSize={10} aria-label="" p={0} colorScheme="none">
              <Image
                draggable={false}
                src="/outline-game-02.png"
                w="100%"
                h="100%"
              />
            </IconButton>
            <Text style={{ fontSize: 14 }}>Strategy</Text>
          </HStack>
          <IconButton boxSize={10} aria-label="" p={0} colorScheme="none">
            <Image
              draggable={false}
              src="/outline-game-03.png"
              w="100%"
              h="100%"
            />
          </IconButton>
          <IconButton boxSize={10} aria-label="" p={0} colorScheme="none">
            <Image
              draggable={false}
              src="/outline-game-04.png"
              w="100%"
              h="100%"
            />
          </IconButton>
        </HStack>
      </Stack>
    </Center>
  );
};

const Features = () => {
  const [activeZone, setActiveZone] = useState("game");

  return (
    <Stack
      color="white"
      spacing={10}
      w={{ base: "sm", md: "100%" }}
      maxW="80rem"
      alignItems="center"
      bg="#0B243D"
      p={{ base: 5, md: 10 }}
      m={20}
      mb={0}
      borderRadius={20}
    >
      <Stack w="100%" spacing={3}>
        <Heading fontSize="1rem" fontWeight={500} color="#209BFF">
          Our Features
        </Heading>
        <Text
          fontSize={{ base: "1.2rem", md: "2rem" }}
          fontWeight={400}
          color="whiteAlpha.600"
          w="100%"
          maxW="58rem"
        >
          <span style={{ color: "White" }}>Kolet is Africa's premier </span>
          mobile gaming platform that rewards{" "}
          <span style={{ color: "White" }}>
            you for your passion for games, by{" "}
          </span>
          integrating social communication and financial services.
        </Text>
      </Stack>
      <FeaturesCards />
    </Stack>
  );
};

const HowItWorks = () => {
  return (
    <Stack
      color="white"
      spacing={8}
      w={{ base: "sm", md: "100%" }}
      maxW="80rem"
      alignItems="center"
      bg="#0B243D"
      p={{ base: 5, md: 20 }}
      m={20}
      borderRadius={20}
    >
      <Heading color="#6D42EF" fontSize="2.5rem" fontWeight={600}>
        How it works
      </Heading>
      <Text textAlign="center">
        Easy to use features interlinked together. Join us today
      </Text>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap={{ base: 20, md: 10 }}
        pos="relative"
      >
        <Box
          h={{ base: "100%", md: "2px" }}
          w={{ base: "2px", md: "100%" }}
          bg="blue.300"
          pos="absolute"
          top={{ md: 0 }}
          bottom={{ md: 0 }}
          left={{ base: 0 }}
          right={{ base: 0 }}
          m="auto"
        />
        <Stack
          zIndex={10}
          p={5}
          bg="#0E3051"
          spacing={5}
          w="xs"
          borderRadius={10}
          borderWidth="1px"
          borderColor="whiteAlpha.300"
        >
          <Heading fontSize="2rem" fontWeight={400}>
            01
          </Heading>
          <Text fontWeight={500}>Download on App/Google store</Text>
          <Text fontWeight={300}>
            Kolet is available as a Free Download via the Google Play Store or
            Apple AppStore.
          </Text>
        </Stack>
        <Stack
          zIndex={10}
          p={5}
          bg="#0E3051"
          spacing={5}
          w="xs"
          borderRadius={10}
          borderWidth="1px"
          borderColor="whiteAlpha.300"
          pos="relative"
        >
          <Icon
            display={{ base: "none", md: "block" }}
            as={IoCaretForwardOutline}
            color="blue.300"
            pos="absolute"
            left="-0.75rem"
            top="0"
            bottom="0"
            m="auto"
          />
          <Icon
            display={{ base: "block", md: "none" }}
            as={IoMdArrowDropdown}
            color="blue.300"
            pos="absolute"
            top="-1.6rem"
            left="0"
            right="0"
            m="auto"
            boxSize={10}
          />
          <Heading fontSize="2rem" fontWeight={400}>
            02
          </Heading>
          <Text fontWeight={500}>Register with Us</Text>
          <Text fontWeight={300}>
            Once you have downloaded the app, you’ll need to Register and then
            verify your identity.
          </Text>
        </Stack>
        <Stack
          zIndex={10}
          p={5}
          bg="#0E3051"
          spacing={5}
          w="xs"
          borderRadius={10}
          borderWidth="1px"
          borderColor="whiteAlpha.300"
          pos="relative"
        >
          <Icon
            display={{ base: "none", md: "block" }}
            as={IoCaretForwardOutline}
            color="blue.300"
            pos="absolute"
            left="-0.75rem"
            top="0"
            bottom="0"
            m="auto"
          />
          <Icon
            display={{ base: "block", md: "none" }}
            as={IoMdArrowDropdown}
            color="blue.300"
            pos="absolute"
            top="-1.6rem"
            left="0"
            right="0"
            m="auto"
            boxSize={10}
          />
          <Heading fontSize="2rem" fontWeight={400}>
            03
          </Heading>
          <Text fontWeight={500}>Enjoy the App</Text>
          <Text fontWeight={300}>
            Play, earn and socialize , start playing varieties of games of your
            choice
          </Text>
        </Stack>
      </Grid>
    </Stack>
  );
};

const FAQs = () => {
  return (
    <Center
      w={{ base: "sm", md: "100%" }}
      bg="#0B243D"
      borderRadius={{ base: 5, md: 0 }}
      px={{ base: 5, md: 0 }}
    >
      <Stack
        color="white"
        py={{ base: 5, md: 20 }}
        spacing={8}
        w="100%"
        maxW="78rem"
        alignItems="center"
      >
        <Heading
          color="#6D42EF"
          fontSize={{ base: "2rem", md: "2.5rem" }}
          fontWeight={600}
          textAlign="center"
        >
          Frequently asked questions
        </Heading>
        <Text textAlign="center" w="100%" maxW="30rem">
          Still have more questions regarding our All-in-one App? Check the
          detailed FAQ with the most common questions.
        </Text>
        <Accordion allowMultiple w="100%" bg="#133C64" p={5} borderRadius={20}>
          {[
            {
              question: "How do I sign up?",
              answer:
                "To initiate the registration process, please visit our official website or download the dedicated application from either the Google Play Store for Android users or the App Store for iOS. Follow the provided instructions to create your account securely.",
            },
            {
              question: "Can I play games for free?",
              answer:
                "You have the option to enjoy a diverse selection of our games without any cost. We offer a variety of free-to-play games to cater to different preferences.",
            },
            {
              question: "How many digital wallets do I have?",
              answer:
                "Users are granted access to four distinct digital wallets, each designed for specific functionalities. These wallets are user-friendly and serve different purposes based on your needs.",
            },
            {
              question: "Is my money safe?",
              answer:
                "Rest assured, we have implemented robust security measures to safeguard your funds. Your money is secure, and no transactions will take place without your explicit approval. Our commitment to security ensures a trustworthy and protected financial environment.",
            },
            {
              question: "How many social channels can I have?",
              answer:
                "Users can access and manage up to five social channels. This feature empowers you to efficiently create and oversee your followers. Additionally, we provide tools to facilitate follower management and engagement. For any specific inquiries or concerns, feel free to contact our customer support team. We are dedicated to ensuring a seamless and secure experience for all our users.",
            },
          ].map((item, index) => (
            <AccordionItem
              borderWidth={0}
              borderBottomWidth={1}
              borderColor="whiteAlpha.400"
              py={3}
              w="100%"
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {item.question}
                      </Box>
                      {isExpanded ? (
                        <AiOutlineMinus size={25} />
                      ) : (
                        <BsPlus size={30} />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{item.answer}</AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
        <Text w={{ base: "100%", md: "md" }} textAlign="center">
          You can ask any other questions by writing us an email and you will be
          responded to in 2-3 business days.
        </Text>
        <Button colorScheme="none" bg="#061523" color="white" borderRadius={20}>
          Ask other questions
        </Button>
      </Stack>
    </Center>
  );
};

const RewardYourPassion = () => {
  const navigate = useNavigate();

  return (
    <Center
      w="100%"
      color="white"
      bg="linear-gradient(90deg, rgba(32,155,255,1) 0%, rgba(12,85,151,1) 95%, rgba(255,255,255,0.7) 100%)"
    >
      <Grid
        alignItems="center"
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        w="100%"
        maxW="78rem"
        gap={{ base: 5, md: 0 }}
      >
        <Image
          draggable={false}
          pt={{ md: 16 }}
          src="/phone-mockups.png"
          w={{ base: "80%" }}
          mx={{ base: "auto", md: "initial" }}
        />
        <GridItem gridRow={1} colStart={{ md: 2 }}>
          <Stack
            alignItems={{ base: "center", md: "flex-start" }}
            spacing={5}
            p={{ base: 5, md: 0 }}
          >
            <Heading fontSize={{ base: "2rem", md: "2.5rem" }} fontWeight={500}>
              Reward your passion
            </Heading>
            <Text
              w={{ base: "100%", md: "md" }}
              textAlign={{ base: "center", md: "left" }}
            >
              With Kolet, effortlessly manage gaming rewards by paying bills
              directly in the app, your go-to for all digital activities.
            </Text>
            <Button
              onClick={() => navigate("/waiting-list")}
              bg="#061523"
              colorScheme="none"
              color="white"
              borderRadius={20}
            >
              Download your app
            </Button>
          </Stack>
        </GridItem>
      </Grid>
    </Center>
  );
};

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Center
      w="100%"
      maxW="85rem"
      bgImage="/footer-bg.png"
      bgRepeat="no-repeat"
      bgSize="20rem"
      bgPos="right"
    >
      <Stack
        spacing={{ base: 10, md: 20 }}
        color="white"
        w="100%"
        p={{ base: 5, md: 10 }}
      >
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={5}
          display={{ base: "grid", md: "none" }}
        >
          <Link>Home</Link>
          <Link>Privacy Policy</Link>
          <Link>Features</Link>
          <Link>Support Us</Link>
          <Link>How it works</Link>
          <Link>Terms & Conditions</Link>
          <Link>FAQs</Link>
          <Link>Careers</Link>
        </Grid>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          w="100%"
          gap={{ base: 8, md: 0 }}
        >
          <Stack alignItems="flex-start" spacing={10}>
            <Image draggable={false} src="/kolet-light.svg" h="3rem" />
            <Text w={{ base: "100%", md: "md" }}>
              Kolet is Africa's premier mobile gaming platform that rewards you
              for your passion for games, by integrating social communication
              and financial services.
            </Text>
            <HStack spacing={5}>
              <Link as={Image} src="/linkedin.svg" w="2.5rem" href="https://www.linkedin.com/company/officialkolet/" />
              <Link as={Image} src="/insta.svg" w="2.5rem" href="https://www.instagram.com/officialkolet/" />
              <Link as={Image} src="/x.svg" w="2.5rem" href="https://twitter.com/officialkolet" />
              <Link as={Image} src="/facebook.svg" w="2.5rem" href="#" />
            </HStack>
          </Stack>
          <Stack
            display={{ base: "flex", md: "none" }}
            color="whiteAlpha.700"
            spacing={5}
          >
            <Text>Download Kolet App</Text>
            <HStack
              justifyContent={{ base: "flex-start", md: "space-between" }}
              spacing={{ base: 5, md: 8 }}
            >
              <Button
                onClick={() => navigate("/waiting-list")}
                variant="unstyled"
                w="8rem"
                h="3rem"
              >
                <Image
                  draggable={false}
                  src="/playstore.png"
                  w="100%"
                  h="100%"
                />
              </Button>
              <Button
                onClick={() => navigate("/waiting-list")}
                variant="unstyled"
                w="8rem"
                h="3rem"
              >
                <Image
                  draggable={false}
                  src="/appstore.png"
                  w="100%"
                  h="100%"
                />
              </Button>
            </HStack>
          </Stack>
          <Flex py={5} display={{ base: "none", md: "flex" }}>
            <Stack spacing={5} w="50%">
              <Link>Home</Link>
              <Link>Features</Link>
              <Link>How it works</Link>
              <Link>FAQs</Link>
            </Stack>
            <Stack spacing={5} w="50%">
              <Link>Privacy Policy</Link>
              <Link>Support Us</Link>
              <Link>Terms & Conditions</Link>
              <Link>Careers</Link>
            </Stack>
          </Flex>
        </Grid>
        <Box bg="whiteAlpha.500" w="100%" h="1px" />
        <Text display={{ base: "block", md: "none" }}>
          Kolet &copy; {new Date().getFullYear()} All rights reserved.
        </Text>
        <Grid
          display={{ base: "none", md: "grid" }}
          templateColumns="repeat(2, 1fr)"
          color="whiteAlpha.700"
          alignItems="center"
        >
          <Text>
            Kolet &copy; {new Date().getFullYear()} All rights reserved.
          </Text>
          <HStack justifyContent="space-between" spacing={8}>
            <Text>Download Kolet App</Text>
            <Button
              onClick={() => navigate("/waiting-list")}
              variant="unstyled"
              w="8rem"
              h="3rem"
            >
              <Image draggable={false} src="/playstore.png" w="100%" h="100%" />
            </Button>
            <Button
              onClick={() => navigate("/waiting-list")}
              variant="unstyled"
              w="8rem"
              h="3rem"
            >
              <Image draggable={false} src="/appstore.png" w="100%" h="100%" />
            </Button>
          </HStack>
        </Grid>
      </Stack>
    </Center>
  );
};

export default LandingPage;
