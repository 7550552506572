import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  styles: {
    global: {
      "*": {
        fontFamily: "Rubik, sans-serif",
        "::-webkit-scrollbar": {
          height: "5px",
          width: "5px",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#FF0467",
          borderRadius: "1rem",
        },
      },
    },
  },
  colors: {
    brand: {
      primary: "#061523",
    },
  },
});
