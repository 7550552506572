import { Button } from "@chakra-ui/react";
import React from "react";

const FeatureSubButton = ({ active, title, variant, ...props }: any) => {
  return (
    <Button
      colorScheme="none"
      bg={
        active
          ? variant === "game"
            ? "linear-gradient(90deg, rgba(109,66,239,1) 0%, rgba(0,234,248,1) 100%)"
            : variant === "talk"
            ? "linear-gradient(90deg, rgba(255,4,103,1) 0%, rgba(252,116,55,1) 100%)"
            : "linear-gradient(90deg, rgba(32,68,255,1) 0%, rgba(249,7,167,1) 100%)"
          : "transparent"
      }
      borderRadius={0}
      {...props}
    >
      {title}
    </Button>
  );
};

export default FeatureSubButton;
