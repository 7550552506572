import { Button } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

const FeatureButton = ({ active, title, ...props }: any) => {
  const { zone } = useSelector((state: RootState) => state.feature);

  return (
    <Button
      px={{ base: 4, md: 5 }}
      borderRadius={40}
      bg={
        title.toLowerCase().includes(zone)
          ? zone === "game"
            ? "linear-gradient(90deg, rgba(109,66,239,1) 0%, rgba(0,234,248,1) 100%)"
            : zone === "talk"
            ? "linear-gradient(90deg, rgba(255,4,103,1) 0%, rgba(252,116,55,1) 100%)"
            : zone === "pay"
            ? "linear-gradient(90deg, rgba(32,68,255,1) 0%, rgba(249,7,167,1) 100%)"
            : "#10365B"
          : "#10365B"
      }
      colorScheme="none"
      fontSize={{ base: "0.8rem", md: "1rem" }}
      fontWeight={400}
      {...props}
    >
      {title}
    </Button>
  );
};

export default FeatureButton;
