import { createSlice } from "@reduxjs/toolkit";

const initialState = { zone: "", disabled: false };

export const featureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    handleFeatureZone: (state, action) => {
      state.zone = action.payload;
    },
    handleToggleDisable: (state) => {
      state.disabled = !state.disabled;
    },
  },
});

export const { handleFeatureZone, handleToggleDisable } = featureSlice.actions;

export default featureSlice.reducer;
