import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { theme } from "./chakra";
import React from "react";
import LandingPage from "./pages/LandingPage";
import WaitingList from "./pages/WaitingList";

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="" element={<LandingPage />} />
          <Route path="/waiting-list" element={<WaitingList />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}
