import {
  Grid,
  Box,
  Stack,
  Heading,
  Image,
  Text,
  HStack,
  Center,
  Button,
  GridItem,
} from "@chakra-ui/react";
import React, { Ref, useEffect, useRef, useState } from "react";
import FeatureSubButton from "../FeatureSubButton";
import FeatureButton from "../FeatureButton";
// import { useInView } from "react-intersection-observer";
import { handleFeatureZone } from "../../features/featureSlice";
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "framer-motion";
import { useNavigate } from "react-router-dom";

const FeaturesCards = () => {
  const navigate = useNavigate();

  return (
    <Stack
      alignItems="center"
      spacing={10}
      pos="relative"
      h={{ base: "fit-content", md: "310vh" }}
      w="100%"
    >
      <Tabs />
      <Stack
        h="100%"
        w="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <GameZone />
        <TalkZone />
        <PayZone />
      </Stack>
      <Stack alignItems="center" spacing={5}>
        <Heading
          fontSize={{ base: "1rem", md: "1.5rem" }}
          fontWeight={500}
          textAlign="center"
          w="100%"
          maxW="30rem"
          lineHeight="2rem"
        >
          Build a life with kolet
        </Heading>
        <HStack>
          <Button
            onClick={() => navigate("/waiting-list")}
            variant="unstyled"
            w="8rem"
            h="3rem"
          >
            <Image draggable={false} src="/playstore.png" w="100%" h="100%" />
          </Button>
          <Button
            onClick={() => navigate("/waiting-list")}
            variant="unstyled"
            w="8rem"
            h="3rem"
          >
            <Image draggable={false} src="/appstore.png" w="100%" h="100%" />
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
};

const Tabs = () => {
  const dispatch = useDispatch();

  return (
    <HStack
      w="fit-content"
      bg="brand.primary"
      p={3}
      borderRadius={40}
      spacing={5}
      pos="sticky"
      top="1.5rem"
    >
      <FeatureButton
        title="Game Zone"
        onClick={() => {
          const element = document.getElementById("gamezone");

          dispatch(handleFeatureZone("game"));
          element?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }}
      />
      <FeatureButton
        title="Talk Zone"
        onClick={() => {
          const element = document.getElementById("talkzone");

          dispatch(handleFeatureZone("talk"));
          element?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }}
      />
      <FeatureButton
        title="Pay Zone"
        onClick={() => {
          const element = document.getElementById("payzone");

          dispatch(handleFeatureZone("pay"));
          element?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }}
      />
    </HStack>
  );
};

const GameZone = () => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLInputElement | null>(null);
  const isInView = useInView(ref, { amount: 0.3 });

  const [modalVisible, setModalVisible] = useState(false);

  const { zone } = useSelector((state: RootState) => state.feature);

  useEffect(() => {
    isInView && dispatch(handleFeatureZone("game"));
  }, [isInView]);

  const [items] = useState([
    {
      title: "Free Games",
      photo: "/gamezone-freegame.png",
    },
    {
      title: "Rankboard Games",
      photo: "/gamezone-rankboard.png",
    },
    {
      title: "Lucky Games",
      photo: "/gamezone-luckygame.png",
    },
    {
      title: "Game store",
      photo: "/gamezone-gamestore.png",
    },
  ]);

  const [currentItem, setCurrentItem] = useState("Free Games");

  return (
    <Grid
      ref={ref}
      id="gamezone"
      zIndex={10}
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
      gap={{ base: 10, md: 28 }}
      p={{ base: 5, md: 10 }}
      background="linear-gradient(180deg, rgba(109,66,239,1) 0%, rgba(0,234,248,0.76) 100%)"
      borderRadius={20}
      w={{ base: "100%", md: "fit-content" }}
      h={{ base: "42rem", md: "fit-content" }}
      // Animation styles
      position={zone != null ? "sticky" : "relative"}
      transform={
        zone === "talk"
          ? "translate(-0.5rem, -0.5rem)"
          : zone === "pay"
          ? "translate(-1rem, -1rem)"
          : "translate(0)"
      }
      top={{ base: "7.5rem", md: "7.5rem" }}
      transition="all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
    >
      <Box pos="relative">
        <Image
          borderRadius={20}
          w={{ base: "80%", md: "100%" }}
          src={items.find((item) => item.title === currentItem)?.photo}
          onMouseEnter={() => setModalVisible(true)}
          onMouseLeave={() => setModalVisible(false)}
        />
        <Stack
          borderRadius={14}
          pos="absolute"
          bg="brand.primary"
          py={3}
          right={{ base: "-0.5rem", md: "-6rem" }}
          top={10}
          bottom={0}
          h="fit-content"
          w="12rem"
          opacity={modalVisible ? 1 : 0}
          onMouseEnter={() => setModalVisible(true)}
          onMouseLeave={() => setModalVisible(false)}
          transition="all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
        >
          {items.map((item, index) => (
            <FeatureSubButton
              variant="game"
              onClick={() => setCurrentItem(item.title)}
              key={index}
              active={item.title === currentItem}
              title={item.title}
            />
          ))}
        </Stack>
      </Box>
      <Stack p={5} justifyContent="center" spacing={8}>
        <Heading w="15rem" fontWeight={500}>
          Play Games & Get rewarded
        </Heading>
        <Text w={{ base: "100%", md: "xs" }} color="whiteAlpha.700">
          Discover more than just gaming on the Kolet app—immerse yourself in
          entertainment, build real connections, and empower your finances, all
          in one place!
        </Text>
      </Stack>
    </Grid>
  );
};

const TalkZone = () => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLInputElement | null>(null);
  const isInView = useInView(ref, { amount: 0.3 });

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    isInView && dispatch(handleFeatureZone("talk"));
  }, [isInView]);

  const { zone } = useSelector((state: RootState) => state.feature);

  const [items] = useState([
    {
      title: "Chat",
      photo: "talkzone-chat.png",
    },
    {
      title: "New feeds",
      photo: "talkzone-channels.png",
    },
    {
      title: "Channels",
      photo: "talkzone-feeds.png",
    },
  ]);

  const [currentItem, setCurrentItem] = useState("Chat");

  return (
    <Grid
      ref={ref}
      id="talkzone"
      zIndex={100}
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
      gap={{ base: 10, md: 28 }}
      p={{ base: 5, md: 10 }}
      bg="linear-gradient(180deg, rgba(255,4,103,1) 0%, rgba(252,116,55,1) 100%)"
      borderRadius={20}
      w={{ base: "100%", md: "fit-content" }}
      h={{ base: "42rem", md: "fit-content" }}
      // Animation styles
      position={zone != null ? "sticky" : "relative"}
      transform={
        zone === "pay" ? "translate(-0.5rem, -0.5rem)" : "translate(0)"
      }
      top={{ base: "7.5rem", md: "7.5rem" }}
      transition="all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
    >
      <Stack p={5} justifyContent="center" spacing={8}>
        <Heading w="100%" maxW="18rem" fontWeight={500}>
          Connect with friends anytime and everywhere
        </Heading>
        <Text w={{ base: "100%", md: "22rem" }} color="whiteAlpha.700">
          Connect with fellow gamers on our social platform, where gaming
          becomes a shared adventure. Chat, team up, and forge lasting
          friendships beyond the virtual world.
        </Text>
      </Stack>
      <GridItem rowStart={1} colStart={{ md: 2 }}>
        <Box pos="relative">
          <Image
            w={{ base: "80%", md: "100%" }}
            h="100%"
            src={items.find((item) => item.title === currentItem)?.photo}
            onMouseEnter={() => setModalVisible(true)}
            onMouseLeave={() => setModalVisible(false)}
          />
          <Stack
            borderRadius={14}
            pos="absolute"
            bg="brand.primary"
            py={3}
            left={{ md: "-6rem" }}
            right={{ base: "-0.5rem", md: 0 }}
            top={10}
            bottom={0}
            h="fit-content"
            w="12rem"
            opacity={modalVisible ? 1 : 0}
            onMouseEnter={() => setModalVisible(true)}
            onMouseLeave={() => setModalVisible(false)}
            transition="all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
          >
            {items.map((item, index) => (
              <FeatureSubButton
                variant="talk"
                onClick={() => setCurrentItem(item.title)}
                key={index}
                active={item.title === currentItem}
                title={item.title}
              />
            ))}
          </Stack>
        </Box>
      </GridItem>
    </Grid>
  );
};

const PayZone = () => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLInputElement | null>(null);
  const isInView = useInView(ref, { amount: 0.3 });

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    isInView && dispatch(handleFeatureZone("pay"));
  }, [isInView]);

  const { zone } = useSelector((state: RootState) => state.feature);

  const [items] = useState([
    {
      title: "Fund Transfer",
      photo: "payzone-fundtransfer.png",
    },
    {
      title: "Bill Payment",
      photo: "payzone-billpayment.png",
    },
  ]);

  const [currentItem, setCurrentItem] = useState("Fund Transfer");

  return (
    <Grid
      overflow="hidden"
      ref={ref}
      id="payzone"
      zIndex={1000}
      templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
      gap={{ base: 10, md: 28 }}
      p={{ base: 5, md: 10 }}
      bg="linear-gradient(90deg, #062A4E 0%, #0E2D4D 100%)"
      borderRadius={20}
      w={{ base: "100%", md: "fit-content" }}
      h={{ base: "42rem", md: "fit-content" }}
      // Animation styles
      position={zone != null ? "sticky" : "relative"}
      // transform="translate(0)"
      top={{ base: "7.5rem", md: "7.5rem" }}
      transition="all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
    >
      <Box pos="relative">
        <Image
          w={{ base: "80%", md: "100%" }}
          src={items.find((item) => item.title === currentItem)?.photo}
          onMouseEnter={() => setModalVisible(true)}
          onMouseLeave={() => setModalVisible(false)}
        />
        <Stack
          borderRadius={14}
          pos="absolute"
          bg="brand.primary"
          py={3}
          right={{ base: "-0.5rem", md: "-6rem" }}
          top={10}
          bottom={0}
          h="fit-content"
          w="12rem"
          opacity={modalVisible ? 1 : 0}
          onMouseEnter={() => setModalVisible(true)}
          onMouseLeave={() => setModalVisible(false)}
          transition="all 2s cubic-bezier(0.17, 0.55, 0.55, 1) 0s"
        >
          {items.map((item, index) => (
            <FeatureSubButton
              variant="pay"
              onClick={() => setCurrentItem(item.title)}
              key={index}
              active={item.title === currentItem}
              title={item.title}
            />
          ))}
        </Stack>
      </Box>
      <Stack p={5} justifyContent="center" spacing={8}>
        <Heading w="100%" maxW="20rem" fontWeight={500}>
          <span
            style={{
              color: currentItem === "Fund Transfer" ? "#5D84AA" : "inherit",
            }}
          >
            Fund transfer{" "}
          </span>{" "}
          and{" "}
          <span
            style={{
              color: currentItem === "Bill Payment" ? "#5D84AA" : "inherit",
            }}
          >
            Bill payment
          </span>
        </Heading>
        <Text w={{ base: "100%", md: "xs" }} color="whiteAlpha.700">
          With Kolet, effortlessly manage gaming rewards by paying bills
          directly in the app, your go-to for all digital activities.
        </Text>
      </Stack>
    </Grid>
  );
};

export default FeaturesCards;
